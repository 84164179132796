import Vue from "vue";
import router from "@/router";
import { VueAuthenticate } from "vue-authenticate";

import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

const vueAuth = new VueAuthenticate(Vue.prototype.$http, {
  baseUrl: process.env.VUE_APP_API_BASE_URL,
  tokenName: "access_token",
  loginUrl: "/login",
  registerUrl: "/register"
});

export default {
  state: {
    isAuthenticated: localStorage.getItem("vue-authenticate.vueauth_access_token") !== null,
    user : null
  },

  getters: {
    isAuthenticated(state) {
      return state.isAuthenticated;
    },
    GET_USER(state) {
      return state.user;
    }
  },

  mutations: {
    isAuthenticated(state, payload) {
      state.isAuthenticated = payload.isAuthenticated;
    },
    SET_USER: (state, payload) => {
      state.user = payload;
    }
  },

  actions: {
    login(context, payload) {
      return vueAuth.login(payload.user, payload.requestOptions).then(response => {

        console.log(response)
        if(response.data.success){
          context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated()
          });
          context.commit("SET_USER",response.data.data);
          router.push({path: '/modules/users'});
        }else{
          this.$notify({
            type: "danger",
            message: "¡Credenciales incorrectas!",
          });
        }
        
      });
    },

    register(context, payload) {
      return vueAuth.register(payload.user, payload.requestOptions).then(response => {
        console.log('response',response);
        if(response.data.success) {
          context.commit("isAuthenticated", {
            isAuthenticated: vueAuth.isAuthenticated()
          });
          // router.push({path: '/login'});
          router.push({path: "/modules/users"});
        }
        
       
      });
    },

    logout(context, payload) {
      return vueAuth.logout().then(response => {
        context.commit("isAuthenticated", {
          isAuthenticated: vueAuth.isAuthenticated()
        });
        router.push({name: "login"});
      });
    }
  }
};
