<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <side-bar :background-color="sidebarBackground" short-title="Simple Form" title="Simple Form">
      <template slot="links">
        <!-- <sidebar-item
          :link="{
            name: 'Dashboard',
            icon: 'ni ni-tv-2 text-primary',
            path: '/dashboard',
          }"
        /> -->

        <!-- <sidebar-item
          :link="{
            name: 'Principal',
            icon: 'ni ni-tv-2 text-primary',
            path: '/main',
          }"
        /> -->

        <!-- <sidebar-item
          :link="{
            name: 'Principal',
            icon: 'ni ni-settings text-primary',
            path: '/setting',
          }"
        /> -->

        <sidebar-item opened :link="{
          name: 'Configuración (API)',
          icon: 'ni ni-settings-gear-65',
        }">
          <!-- <sidebar-item
            :link="{ name: 'User Profile', path: '/modules/user-profile' }"
          /> -->
          <!-- <sidebar-item
            :link="{
              name: 'User Management',
              path: '/modules/user-management/list-users',
            }"
          /> -->
          <sidebar-item :link="{
            name: 'Módulo Usuarios',
            path: '/modules/users',
          }" />
          <sidebar-item isHidden :link="{
            name: 'Crear Usuario',
            path: '/modules/create-user',
          }" />
          <sidebar-item :link="{
            name: 'Módulo Sección',
            path: '/modules/sections',
          }" />
          <sidebar-item :link="{
            name: 'Módulo Familias',
            path: '/modules/families',
          }" />

          <sidebar-item :link="{
            name: 'Módulo Atributos',
            path: '/modules/attributes',
          }" />
          <sidebar-item isHidden :link="{
            name: 'Crear Atributo',
            path: '/modules/create-attribute',
          }" />
          <sidebar-item :link="{
            name: 'Módulo Formularios',
            path: '/modules/forms',
          }" />
        </sidebar-item>

        <sidebar-item opened :link="{
          name: 'Configuración (App)',
          icon: 'ni ni-app',
        }">
         <sidebar-item :link="{
            name: 'Formularios App',
            path: '/modules/forms-app',
          }" />
        </sidebar-item>
        <!-- <sidebar-item
            isHidden
            :link="{
              name: 'Visualizar Formulario',
              path: '/modules/preview-form',
            }"
          /> -->

        <!-- <sidebar-item
          :link="{
            name: 'Icons',
            icon: 'ni ni-planet text-blue',
            path: '/icons',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Maps',
            icon: 'ni ni-pin-3 text-orange',
            path: '/maps',
          }"
        />
        <sidebar-item
          :link="{
            name: 'User Profile',
            icon: 'ni ni-single-02 text-yellow',
            path: '/profile',
          }"
        />
        <sidebar-item
          :link="{
            name: 'Tables',
            icon: 'ni ni-bullet-list-67 text-red',
            path: '/tables',
          }"
        /> -->

        <hr class="my-3" style="
            border: 0;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            min-width: 80%;
            overflow: visible;
            box-sizing: content-box;
            height: 0;
          " />

        <!-- <a
          href="https://www.creative-tim.com/product/vue-argon-dashboard-pro-laravel"
          target="_blank"
          class="btn btn-danger btn-icon ml-sm-3 d-md-block mb-2 mt-1 mr-3"
          ><span class="btn-inner--icon"
            ><i class="fas fa-download mr-2"></i
          ></span>
          <span class="nav-link-inner--text">Upgrade to PRO</span></a
        > -->

        <!-- <a
          href="https://vue-argon-dashboard-laravel.creative-tim.com/documentation/"
          target="_blank"
          class="btn btn-neutral btn-icon ml-sm-3 d-md-block mb-2 mr-3"
          ><span class="btn-inner--icon"
            ><i class="fas fa-file-alt mr-2"></i
          ></span>
          <span class="nav-link-inner--text">Documentation</span></a
        > -->

        <!-- <a
          href="https://www.creative-tim.com/product/vue-argon-dashboard-laravel"
          target="_blank"
          class="btn btn-info btn-icon ml-sm-3 d-md-block mb-2 mr-3"
          ><span class="btn-inner--icon"><i class="fas fa-gift mr-2"></i></span>
          <span class="nav-link-inner--text">Download now</span></a
        > -->

        <!-- <a
          href="https://github.com/creativetimofficial/vue-argon-dashboard-laravel"
          target="_blank"
          class="btn btn-dark btn-icon ml-sm-3 d-md-block mr-2 mr-3"
          ><span class="btn-inner--icon"
            ><i class="fab fa-github mr-2"></i
          ></span>
          <span class="nav-link-inner--text">Star us on Github</span></a
        > -->
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
  </div>
</template>
<script>
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import { FadeTransition } from "vue2-transitions";

export default {
  components: {
    DashboardNavbar,
    ContentFooter,
    FadeTransition,
  },
  data() {
    return {
      user: null,
      sidebarBackground: "vue", //vue|blue|orange|green|red|primary
    };
  },
  mounted() {
    this.user = this.$store.getters.GET_USER;
    console.log('Usuario', this.user);
  },
  computed: {
    isSupervisor() {
      return (this.user?.role?.description == 'Supervisor') ? true : false;
    },
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
  },
};
</script>
<style lang="scss"></style>
