<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
        </base-header>
        <div class="container-fluid mt-5">

            <div class="card">
                <div class="content card-header">
                    <h1>Editar usuario</h1>
                    <base-button type="primary" icon size="sm" @click="$router.back()">
                        <i class="fas fa fa-arrow-left mr-2"></i>
                        <span class="btn-inner--text">Regresar</span>
                    </base-button>
                </div>
                <div class="card-body">
                    <form role="form" @submit.prevent="handleSubmit()">
                        <div class="row">
                            <div class="col-lg-6">
                                <base-input label="Nombre" placeholder="" v-model="name" />
                                <validation-error :errors="apiValidationErrors.name" />
                            </div>
                            <div class="col-lg-6">
                                <base-input label="Correo" placeholder="name@example.com" v-model="email" />
                                <validation-error :errors="apiValidationErrors.email" />
                            </div>
                            <div class="col-lg-6">
                                <base-input label="Tipo usuario">
                                    <select v-model="role" class="form-control">
                                        <option v-for="option in selectRoles" :key="option.id" :value="option.id">
                                            {{ option.id }} - {{ option.description }}
                                        </option>
                                    </select>
                                </base-input>

                                <validation-error :errors="apiValidationErrors.role" />
                            </div>
                            <div class="col-lg-6">
                                <base-input label="Empresas">
                                    <select v-model="companies" multiple="" class="form-control">
                                        <option v-for="option in selectCompanies" :key="option.id" :value="option.id">
                                            {{ option.id }} - {{ option.description }}
                                        </option>
                                    </select>
                                </base-input>
                                <validation-error :errors="apiValidationErrors.companies" />
                            </div>
                        </div>

                        <div class="text-center">
                            <base-button type="primary" native-type="submit" class="my-4">Guardar</base-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>

import router from "@/router";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import Password from "vue-password-strength-meter";
import lisServices from '../../store/services/getList-service';
import { Select, Option } from 'element-ui'

export default {
    components: {
        ValidationError,
        [Select.name]: Select,
        [Option.name]: Option
    },
    mounted() {
        console.log('Component mounted')
        this.getRoles();
        this.getCompanies();
        this.getUser(this.$route.params.id);
    },
    mixins: [formMixin],
    data() {
        return {
            user: null,
            name: null,
            boolean: false,
            email: null,
            password: null,
            password_confirmation: null,
            scors: "",
            role: null,
            companies: [],
            selectRoles: [],
            selectCompanies: [],
        };
    },

    methods: {
        async getUser(userId) {
            let response = await lisServices.getUser(userId);
            this.user = response.list;
            this.name = this.user.name;
            this.email = this.user.email;
            this.role = this.user.role_id;
            this.user.companies.map(item => {
                this.companies.push(item.id);
            });
        },
        showScore(score) {
            this.scors = score;
        },
        async getRoles() {
            let response = await lisServices.getListRoles();
            console.log(response);

            this.selectRoles = response.list;
            console.log('Roles', this.selectRoles);
        },
        async getCompanies() {
            let response = await lisServices.getListCompanies();
            console.log('Companies', response);

            this.selectCompanies = response.list;
            console.log(this.selectCompanies);
        },
        async handleSubmit() {

            const user = {
                id: this.$route.params.id,
                name: this.name,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
                role: this.role,
                companies: this.companies,
            };

            const requestOptions = {
                headers: {
                    Accept: "application/vnd.api+json",
                    "Content-Type": "application/vnd.api+json",
                },
            };

            try {
                let response = await lisServices.updateUser(user);

                console.log(response);
                if (response.status) {
                    this.$notify({
                        type: "success",
                        message: "¡Operación ejecutada correctamente!",
                    });
                    router.push({ path: '/modules/users' });
                } else {
                    this.$notify({
                        type: "danger",
                        message: response.message,
                    });
                }
            } catch (error) {
                this.$notify({
                    type: "danger",
                    message: "¡Error en la ejecución de la operación!",
                });
                this.setApiValidation(error.response.data.errors);
            }
        },
    },
};
</script>
<style>
.content {
    display: inline-flex !important;
    justify-content: space-between !important;
}
</style>
      