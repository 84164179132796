<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
        </base-header>
        <div class="container-fluid mt-5">
            <card body-classes="px-0 pb-1" footer-classes="pb-2">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-0">Listado de Usuarios</h3>
                        </div>
                        <div class="col text-right">
                            <!-- <router-link :to="{ name: 'Crear Usuario' }" class="mb-0 btn bg-gradient-success btn-sm">
                                <i class="fa fa-plus me-1"></i><span class="btn-inner--text">Agregar Usuario</span>
                            </router-link> -->
                            <base-button type="primary" icon size="sm" @click="createUser">
                                <i class="fas fa fa-plus mr-2"></i>
                                <span class="btn-inner--text">Agregar Usuario</span>
                            </base-button>
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <base-table class="table align-items-center table-flush" tbody-classes="list" :data="users"
                        :thead-classes="'thead-light'">
                        <template slot="columns">
                            <th>ID</th>
                            <th>Nombre</th>
                            <th>Correo</th>
                            <th>Tipo</th>
                            <th>Acciones</th>
                        </template>

                        <template slot-scope="{ row }">
                            <td>
                                {{ row.id }}
                            </td>
                            <td>
                                {{ row.attributes.name }}
                            </td>
                            <td>
                                {{ row.attributes.email }}
                            </td>
                            <td>
                                {{ row.attributes.role.description }}
                            </td>
                            <td>
                                <a style="cursor: pointer" @click="editUser(row.id)" class="mr-2">
                                    <i class="fas fa-user-edit"></i>
                                </a>
                                <a style="cursor: pointer" @click="openModal(row.id)">
                                    <i class="fas fa-trash"></i>
                                </a>
                            </td>
                        </template>
                    </base-table>
                </div>

                 <!--Classic modal-->
                 <modal :show.sync="showAlert">
                <h3 slot="header" class="modal-title">Eliminar</h3>
                <p>¿Estás seguro que quieres eliminar el usuario?</p>
                <template slot="footer">
                    <base-button type="primary" @click="deleteUser">Aceptar</base-button>
                    <base-button type="link" class="ml-auto" @click="showAlert = false">Cancelar</base-button>
                </template>

                </modal>

                <div class="card-footer d-flex justify-content-end">
                    <base-pagination total="1"></base-pagination>
                </div>
            </card>
        </div>
    </div>
</template>
<script>

import router from "@/router";
import lisServices from '../../store/services/getList-service';

export default {
    data() {
        return {
            users: [],
            showAlert: false,
            userId: null
        };
    },
    methods: {
        openModal(id) {
            this.showAlert=true;
            this.userId = id;
        },
        createUser() {
            router.push({path: '/modules/create-user'});
            // this.$router.push('/dashboard')
            // this.$notify({
            //     type: "danger",
            //     message: "This is a PRO feature.",
            // });
        },
        async getUsers() {
            let response = await lisServices.getListUsers();
            console.log('Users', response);
            this.users = response.list;
            console.log(this.users);
        },
        editUser(id) {
            console.log(id)
            router.push({ path: '/modules/edit-user/'+id });
            // this.$router.push('/dashboard')
            
        },
        async deleteUser(){
            let response = await lisServices.deleteUser(this.userId);
            console.log('Attributes', response);
            this.showAlert = false;
            if(response.status){
                this.getUsers();
                this.$notify({
                    type: "success",
                    message: "¡Operación ejecutada correctamente!",
                });
            }else{
                this.$notify({
                    type: "danger",
                    message: "Error en la operación",
                });
            }
           
        }
    },
    mounted() {
        this.getUsers()
    },
};
</script>
<style></style>
  