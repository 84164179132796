<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
        </base-header>
        <div class="container-fluid mt-5">
            <card body-classes="px-0 pb-1" footer-classes="pb-2">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-0">Listado de Formularios</h3>
                        </div>
                        <!-- <div class="col text-right">
                            <base-button type="primary" icon size="sm" @click="createForm">
                                <i class="fas fa fa-plus mr-2"></i>
                                <span class="btn-inner--text">Agregar Formulario</span>
                            </base-button>
                        </div> -->
                    </div>
                </div>
                <div class="card-body">
                    <form role="form" @submit.prevent="getListFormsValue()">
                        <div class="row">
                            <div class="col-lg-4">
                                <base-input label="Usuarios">
                                    <select v-model="user" class="form-control">
                                        <option label="todos" value=null>
                                        </option>
                                        <option v-for="option in selectUsers" :key="option.id" :value="option.id">
                                            {{ option.id }} - {{ option.attributes.name }}
                                        </option>
                                    </select>
                                </base-input>
                            </div>
                            <div class="col-lg-4">
                                <base-input label="Empresas">
                                    <select v-model="company" class="form-control">
                                        <option label="todos" value=null>
                                        </option>
                                        <option v-for="option in selectCompanies" :key="option.id" :value="option.id">
                                            {{ option.id }} - {{ option.description }}
                                        </option>
                                    </select>
                                </base-input>
                            </div>
                            <div class="col-lg-4">
                                <base-input label="Formularios">
                                    <select v-model="form" class="form-control">
                                        <option label="todos" value=null>
                                        </option>
                                        <option v-for="option in selectForms" :key="option.id" :value="option.id">
                                            {{ option.id }} - {{ option.title }}
                                        </option>
                                    </select>
                                </base-input>
                            </div>

                            <div class="col-lg-6">
                                <base-input label="Fecha inicio" v-model="startDate" type="date" />
                            </div>

                            <div class="col-lg-6">
                                <base-input label="Fecha fin" v-model="endDate" type="date" />
                            </div>


                        </div>

                        <div class="text-center">
                            <base-button type="primary" native-type="submit" class="my-4">Buscar</base-button>
                            <base-button type="success" native-type="button" class="my-4" @click="exportAll">Exportar
                                Todo</base-button>
                        </div>
                    </form>
                </div>

                <div class="table-responsive">
                    <base-table class="table align-items-center table-flush" tbody-classes="list" :data="forms"
                        :thead-classes="'thead-light'">
                        <template slot="columns">
                            <th>ID</th>
                            <th>Formulario ID</th>
                            <th>Formulario</th>
                            <th>Atributo</th>
                            <th>Valor</th>
                            <th>Acciones</th>
                        </template>

                        <template slot-scope="{ row }">
                            <td>
                                {{ row.id }}
                            </td>
                            <td>
                                {{ row.form.id }}
                            </td>
                            <td>
                                {{ row.form.title }}
                            </td>
                            <td>
                                {{ row.attribute.title }}
                            </td>
                            <td>
                                <img class="img-avatar" v-if="row.attribute.input.description == 'image'" alt=""
                                    :src="row.value" />
                                <label v-if="row.attribute.input.description != 'image'" for=""> {{ row.value }}</label>
                            </td>
                            <td>
                                <!-- <a style="cursor: pointer" @click="editForm(row.id)" class="mr-2">
                                    <i class="fas fa-user-edit"></i>
                                </a> -->
                                <a style="cursor: pointer" @click="exportRow(row.id)" class="mr-2">
                                    <i class="fas fa-download"></i>
                                </a>
                                <a style="cursor: pointer" @click="openModal(row.id)">
                                    <i class="fas fa-trash"></i>
                                </a>
                            </td>
                        </template>
                    </base-table>
                </div>
                <!--Classic modal-->
                <modal :show.sync="showAlert">
                    <h3 slot="header" class="modal-title">Eliminar</h3>
                    <p>¿Estás seguro que quieres eliminar el registro?</p>
                    <template slot="footer">
                        <base-button type="primary" @click="deleteForm">Aceptar</base-button>
                        <base-button type="link" class="ml-auto" @click="showAlert = false">Cancelar</base-button>
                    </template>

                </modal>
                <div class="card-footer d-flex justify-content-end">
                    <base-pagination total="1"></base-pagination>
                </div>
            </card>
        </div>
    </div>
</template>
<script>

import router from "@/router";
import lisServices from '../../store/services/getList-service';

export default {
    data() {
        return {
            forms: [],
            showAlert: false,
            formId: null,
            selectCompanies: [],
            selectForms: [],
            selectUsers: [],
            company: null,
            form: null,
            user: null,
            startDate: null,
            endDate: null
        };
    },
    methods: {
        openModal(id) {
            this.showAlert = true;
            this.formId = id;
        },
        async deleteForm() {
            let response = await lisServices.deleteFormValue(this.formId);
            console.log('Form', response);
            this.showAlert = false;
            if (response.status) {
                this.getListFormsValue();
                this.$notify({
                    type: "success",
                    message: "¡Operación ejecutada correctamente!",
                });
            } else {
                this.$notify({
                    type: "danger",
                    message: "Error en la operación",
                });
            }

        },
        editForm(id) {
            console.log(id)
            router.push({ path: '/modules/edit-form/' + id });
            // this.$router.push('/dashboard')

        },
        async getListFormsValue() {

            const data = {
                userId: this.user,
                formId: this.form,
                companyId: this.company,
                startDate: this.startDate,
                endDate: this.endDate
            };
            let response = await lisServices.getListFormsValue(data);
            console.log('Forms', response);
            this.forms = response.list;
            console.log(this.forms);
        },
        async getCompanies() {
            let response = await lisServices.getListCompanies();
            console.log('Companies', response);

            this.selectCompanies = response.list;
            console.log(this.selectCompanies);
        },
        async getListForms() {
            let response = await lisServices.getListForms();
            console.log('Forms', response);
            this.selectForms = response.list;
            console.log(this.selectForms);
        },
        async getUsers() {
            let response = await lisServices.getListUsers();
            console.log('Users', response);
            this.selectUsers = response.list;
            console.log(this.selectUsers);
        },
        createForm() {
            router.push({ path: '/modules/create-form' });
        },
        async exportRow(id) {
            await lisServices.exportRow(id);

        },
        async exportAll() {
            await lisServices.exportAllRow(this.user, this.company, this.form, this.startDate, this.endDate);

        }
    },
    mounted() {
        this.getListFormsValue();
        this.getListForms();
        this.getCompanies();
        this.getUsers();
    },
};
</script>
<style>
.img-avatar {
    height: 100px;
    width: 100px;
}
</style>
  