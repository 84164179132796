<template>
  <component :is="layoutComponent">
    <template slot="nav">
      <div class="nav-wrapper">
        <ul class="nav" role="tablist" :class="[type ? `nav-pills-${type}` : '',
        pills ? 'nav-pills' : 'nav-tabs',
        { 'nav-pills-icons': icons },
        { 'nav-fill': fill },
        { 'nav-pills-circle': circle },
        { 'justify-content-center': centered },
          tabNavClasses
        ]">

          <li v-for="tab in tabs" class="nav-item" :key="tab.id">

            <a data-toggle="tab" role="tab" class="nav-link" :href="`#${tab.id}`" @click.prevent="activateTab(tab)"
              :aria-selected="tab.active" :class="{ active: tab.active }">
              <tab-item-content :tab="tab">
              </tab-item-content>
            </a>

          </li>
        </ul>

      </div>
      <div class="tab-content" id="myTabContent">

        <div class="tab-pane fade" :class="{ 'active show': true }" id="tabActive.id">
          <div class="container-fluid mt-5">
            <div class="card" v-for="item in tabActive.families" :key="item.id">
              <div class="card-header">
                <h1>{{ item.title }}</h1>
              </div>
              <div class="card-body">
                <div class="row" v-for="val in item.attributes" :key="val.id">

                  <div class="col-lg-6" v-if="val.input_type_id == 5">
                    <base-input :label="val.title">
                      <select class="form-control">
                        <!-- <option>1</option> -->
                        <option v-for="option in parsearObjetos(val.value)" :key="option.id" :label="option.name"
                          :value="option.id">
                        </option>
                      </select>
                    </base-input>
                  </div>

                  <div class="col-lg-6" v-if="val.input_type_id == 6">
                    <base-input :label="val.title">
                      <textarea class="form-control" rows="3"></textarea>
                    </base-input>
                  </div>

                  <div class="col-lg-6" v-if="val.input_type_id == 7">
                    <base-input :label="val.title" type="text" placeholder="" addon-left-icon="ni ni-camera-compact"
                    disabled />
                  </div>

                  <div class="col-lg-6" v-if="val.input_type_id != 5 && val.input_type_id != 6 && val.input_type_id != 7">
                    <base-input :label="val.title" :type="val.input.description" placeholder="" />
                  </div>


                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <div slot="content" class="tab-content" :class="[tabContentClasses]">
      <slot v-bind="slotData"></slot>
    </div>
  </component>
</template>

<script>
import PillsLayout from "./PillsLayout";
import TabsLayout from "./TabsLayout";

export default {
  name: "tabs",
  components: {
    TabsLayout,
    PillsLayout,
    TabItemContent: {
      props: ["tab"],
      render(h) {
        return h("div", [this.tab.title || this.tab.$slots.title]);
      }
    }
  },
  props: {
    type: {
      type: String,
      default: "",
      validator: value => {
        let acceptedValues = [
          "",
          "primary",
          "info",
          "success",
          "warning",
          "danger"
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: "Tabs type (primary|info|danger|default|warning|success)"
    },
    pills: {
      type: Boolean,
      default: true,
      description: "Whether tabs are pills"
    },
    circle: {
      type: Boolean,
      default: false,
      description: "Whether tabs are circle"
    },
    fill: {
      type: Boolean,
      default: true,
      description: "Whether to fill each tab"
    },
    activeTab: {
      type: String,
      default: "",
      description: "Default active tab name"
    },
    tabNavWrapperClasses: {
      type: [String, Object],
      default: "",
      description: "Tab Nav wrapper (div) css classes"
    },
    tabNavClasses: {
      type: [String, Object],
      default: "",
      description: "Tab Nav (ul) css classes"
    },
    tabContentClasses: {
      type: [String, Object],
      default: "",
      description: "Tab content css classes"
    },
    icons: {
      type: Boolean,
      description: "Whether tabs should be of icon type (small no text)"
    },
    centered: {
      type: Boolean,
      description: "Whether tabs are centered"
    },
    value: {
      type: String,
      description: "Initial value (active tab)"
    },
    tabs: {
      type: Array,
      default: () => []
    }

  },
  provide() {
    return {
      addTab: this.addTab,
      removeTab: this.removeTab
    };
  },
  data() {
    return {
      options: [],
      activeTabIndex: 0,
      tabActive: null
    };
  },
  computed: {
    layoutComponent() {
      return this.pills ? "pills-layout" : "tabs-layout";
    },
    slotData() {
      return {
        activeTabIndex: this.activeTabIndex,
        tabs: this.tabs
      };
    },
    parsearObjetos() {
      return (cadena) => {
        try {
        // Reemplazar las comillas simples por comillas dobles
        const cadenaModificada = cadena.replace(/'/g, '"');
        let information = JSON.parse(cadenaModificada)
        // Parsear la cadena modificada
        // let data = JSON.parse(information);
        return  information;
      } catch (error) {
        console.error('Error al parsear los objetos:', error);
        return [];
      }
      };
      
    },
  },
  methods: {
    findAndActivateTab(title) {

      let tabToActivate = this.tabs.find(t => t.title === title);
      if (tabToActivate) {
        this.activateTab(tabToActivate);
      }
    },
    activateTab(tab) {

      this.tabActive = tab;
      console.log('Activo', this.tabActive)
      if (this.handleClick) {
        this.handleClick(tab);
      }
      this.deactivateTabs();
      tab.active = true;
      this.activeTabIndex = this.tabs.findIndex(t => t.active);
    },
    deactivateTabs() {
      this.tabs.forEach(tab => {
        tab.active = false;
      });
    },
    addTab(tab) {
      if (this.activeTab === tab.name) {
        tab.active = true;
      }
      this.tabs.push(tab);
    },
    removeTab(tab) {
      const tabs = this.tabs;
      const index = tabs.indexOf(tab);
      if (index > -1) {
        tabs.splice(index, 1);
      }
    },
    isActive(tab) {
      return this.activeTab.active
    },
    // setActive (menuItem) {
    //   this.activeItem = menuItem
    // }
  },
  mounted() {
    console.log('TABS PROPS', this.tabs)
    this.$nextTick(() => {
      if (this.value) {
        this.findAndActivateTab(this.value);
      } else {
        if (this.tabs.length > 0) {
          this.activateTab(this.tabs[0]);
        }
      }
    });
  },
  watch: {
    value(newVal) {
      this.findAndActivateTab(newVal);
    }
  }
};
</script>
