<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
        </base-header>
        <div class="container-fluid mt-5">

            <div class="card">
                <div class="content card-header">
                    <h1>Editar Familia</h1>
                    <base-button type="primary" icon size="sm" @click="$router.back()">
                        <i class="fas fa fa-arrow-left mr-2"></i>
                        <span class="btn-inner--text">Regresar</span>
                    </base-button>
                </div>
                <div class="card-body">
                    <form role="form" @submit.prevent="handleSubmit()">

                        <div class="row">
                            <div class="col-lg-6">
                                <base-input label="Título" placeholder="" v-model="title" />
                                <validation-error :errors="apiValidationErrors.title" />
                            </div>
                            <div class="col-lg-5">
                                <base-input label="Logo">
                                    <select v-model="icon" class="form-control">
                                        <option v-for="option in iconsOptions" :key="option.name" :label="option.name"
                                            :value="option.name">
                                        </option>
                                    </select>
                                </base-input>
                                <!-- <base-input label="Logo" placeholder="" v-model="icon" /> -->
                                <!-- <validation-error :errors="apiValidationErrors.email" /> -->
                            </div>
                            <div class="col-lg-1">
                                <ion-icon :name="icon" size="large" class="icon-select"></ion-icon>
                            </div>
                            <div class="col-lg-6">
                                <base-input label="Sección">
                                    <select v-model="section" class="form-control">
                                        <option v-for="option in sectionsOptions" :key="option.id"
                                            :value="option.id">
                                            {{ option.id }} - {{ option.title }}
                                        </option>
                                    </select>
                                </base-input>
                                <validation-error :errors="apiValidationErrors.section" />
                            </div>

                            <div class="col-lg-6">
                                <base-input label="Orden">
                                    <select v-model="orden" class="form-control">
                                        <option v-for="option in ordenOptions" :key="option" :label="option"
                                            :value="option">
                                        </option>
                                    </select>
                                </base-input>
                                <validation-error :errors="apiValidationErrors.orden" />
                            </div>
                            <div class="col-lg-6">
                                <base-input label="Subtotal" placeholder="" v-model="subtotal" />
                                <validation-error :errors="apiValidationErrors.subtotal" />
                            </div>
                        </div>

                        <div class="text-center">
                            <base-button type="primary" native-type="submit" class="my-4">Guardar</base-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>

import router from "@/router";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import lisServices from '../../store/services/getList-service';
import { Select, Option } from 'element-ui'

export default {
    components: {
        ValidationError,
        [Select.name]: Select,
        [Option.name]: Option
    },
    mounted() {
        console.log('Component mounted', this.$route.params.id);
        this.getFamily(this.$route.params.id)
        this.getLisApiIcons();
        this.getSections();
    },
    mixins: [formMixin],
    data() {
        return {
            family: null,
            title: null,
            icon: null,
            orden: null,
            section: null,
            subtotal: null,
            sectionOptions: [],
            ordenOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            iconsOptions: [],
            sectionsOptions: []
        };
    },

    methods: {
        async getFamily(familyId) {
            let response = await lisServices.getFamily(familyId);
            this.family = response.list;
            console.log(this.family);
            this.title = this.family.title;
            this.icon = this.family.icon;
            this.orden = this.family.orden;
            this.section = this.family.section_id;
            this.subtotal = this.family.subtotal;
        },
        async getLisApiIcons() {
            let responseUser = await lisServices.getListApiIcons();
            this.iconsOptions = responseUser.list;
            console.log('Icons', this.iconsOptions);
        },
        async getSections() {
            let response = await lisServices.getListSections();
            this.sectionsOptions = response.list;
            console.log('Sections', this.sectionsOptions);
        },
        async handleSubmit() {

            const data = {
                id: this.$route.params.id,
                title: this.title,
                icon: this.icon,
                section: this.section,
                subtotal: this.subtotal,
                orden: this.orden
            };

            console.log('QUERY ->', data);

            try {
                let response = await lisServices.updateFamily(data);
                console.log(response);
                if (response.status) {
                    this.$notify({
                        type: "success",
                        message: "¡Operación ejecutada correctamente!",
                    });
                    router.push({ path: '/modules/families' });
                } else {
                    this.$notify({
                        type: "danger",
                        message: response.message,
                    });
                }

            } catch (error) {
                console.log(error)
                this.$notify({
                    type: "danger",
                    message: "¡Error en la ejecución de la operación!",
                });
            }
        },
    },
};
</script>
<style>
.content {
    display: inline-flex !important;
    justify-content: space-between !important;
}

.icon-select {
    margin-top: 30px;
}
</style>
      