import axios from "axios";
import Jsona from "jsona";
import { CONSTANTS } from '../../constants/constants';

const url = process.env.VUE_APP_API_BASE_URL;
const jsona = new Jsona();

function getListRoles() {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_LIST_ROLES}`, options).then(response => {
        return {
            list: response.data.data,
        };
    });
}

function getListCompanies() {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_LIST_COMPANIES}`, options).then(response => {
        return {
            list: response.data.data,
        };
    });
}

function getListCompaniesByUser(userId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_LIST_COMPANIES_BY_USER}/${userId}`, options).then(response => {
        return {
            list: response.data.data,
        };
    });
}

function getListSections() {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_LIST_SECTIONS}`, options).then(response => {
        return {
            list: response.data.data,
        };
    });
}

function getListFamilies() {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_LIST_FAMILIES}`, options).then(response => {
        return {
            list: response.data.data,
        };
    });
}

function getListUsers() {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_LIST_USERS}`, options).then(response => {
        return {
            list: response.data.data,
        };
    });
}

function getListAttributes() {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_LIST_ATTRIBUTES}`, options).then(response => {
        return {
            list: response.data.data,
        };
    });
}

function getListInputs() {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_LIST_INPUTS}`, options).then(response => {
        return {
            list: response.data.data,
        };
    });
}

function saveAttribute(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .post(`${url}/${CONSTANTS.SAVE_ATTRBUTES}`, payload, options)
        .then(response => {
            return response.data;
        });
}

function updateAttribute(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .put(`${url}/${CONSTANTS.UPDATE_ATTRBUTE}`, payload, options)
        .then(response => {
            return response.data;
        });
}

function deleteAttribute(attributeId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .delete(`${url}/${CONSTANTS.DELETE_ATTRIBUTE}/${attributeId}`, options)
        .then(response => {
            return response.data;
        });
}

function getListForms() {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_LIST_FORMS}`, options).then(response => {
        return {
            list: response.data.data,
        };
    });
}

function getListFamiliesBySection(sectionId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_LIST_FAMILIES_BY_SECTION}/${sectionId}`, options).then(response => {
        return {
            list: response.data.data,
        };
    });
}

function getListAttributesByFamily(familyId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_LIST_ATTRIBUTES_BY_FAMILY}/${familyId}`, options).then(response => {
        return {
            list: response.data.data,
        };
    });
}

function saveForm(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .post(`${url}/${CONSTANTS.SAVE_FORM}`, payload, options)
        .then(response => {
            return response.data;
        });
}

function saveSection(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .post(`${url}/${CONSTANTS.SAVE_SECTION}`, payload, options)
        .then(response => {
            return response.data;
        });
}

function saveFamily(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .post(`${url}/${CONSTANTS.SAVE_FAMILY}`, payload, options)
        .then(response => {
            return response.data;
        });
}

function getListApiIcons() {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_LIST_API_ICONS}`, options).then(response => {
        return {
            list: response.data.data,
        };
    });
}

function getAttribute(attributeId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_ATTRIBUTE}/${attributeId}`, options).then(response => {
        console.log(response)
        return {
            list: response.data.data,
        };
    });
}

function getUser(userId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_USER}/${userId}`, options).then(response => {
        console.log(response)
        return {
            list: response.data.data,
        };
    });
}

function updateUser(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .put(`${url}/${CONSTANTS.UPDATE_USER}`, payload, options)
        .then(response => {
            return response.data;
        });
}

function deleteUser(userId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .delete(`${url}/${CONSTANTS.DELETE_USER}/${userId}`, options)
        .then(response => {
            return response.data;
        });
}

function getSection(sectionId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_SECTION}/${sectionId}`, options).then(response => {
        console.log(response)
        return {
            list: response.data.data,
        };
    });
}

function updateSection(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .put(`${url}/${CONSTANTS.UPDATE_SECTION}`, payload, options)
        .then(response => {
            return response.data;
        });
}

function deleteSection(sectionId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .delete(`${url}/${CONSTANTS.DELETE_SECTION}/${sectionId}`, options)
        .then(response => {
            return response.data;
        });
}

function getFamily(familyId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_FAMILY}/${familyId}`, options).then(response => {
        console.log(response)
        return {
            list: response.data.data,
        };
    });
}

function updateFamily(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .put(`${url}/${CONSTANTS.UPDATE_FAMILY}`, payload, options)
        .then(response => {
            return response.data;
        });
}

function deleteFamily(familyId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .delete(`${url}/${CONSTANTS.DELETE_FAMILY}/${familyId}`, options)
        .then(response => {
            return response.data;
        });
}

function getForm(formId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.get(`${url}/${CONSTANTS.GET_FORM}/${formId}`, options).then(response => {
        console.log(response)
        return {
            list: response.data.data,
        };
    });
}

function updateForm(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .put(`${url}/${CONSTANTS.UPDATE_FORM}`, payload, options)
        .then(response => {
            return response.data;
        });
}

function deleteForm(formId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .delete(`${url}/${CONSTANTS.DELETE_FORM}/${formId}`, options)
        .then(response => {
            return response.data;
        });
}

function deleteFormValue(formId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios
        .delete(`${url}/${CONSTANTS.DELETE_FORM_VALUE}/${formId}`, options)
        .then(response => {
            return response.data;
        });
}

function exportRow(rowId) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    window.open(`${url}/${CONSTANTS.EXPORT_FORM_ATTRIBUTE}/${rowId}`, '_blank');
}

function exportAllRow(userId,companyId,formId,startDate,endDate) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    window.open(`${url}/${CONSTANTS.EXPORT_FORM_ALL_ATTRIBUTE}/${userId}/${companyId}/${formId}/${startDate}/${endDate}`, '_blank');
}

function getListFormsValue(payload) {
    const options = {
        headers: {
            Accept: "application/vnd.api+json",
            "Content-Type": "application/vnd.api+json"
        }
    };

    return axios.post(`${url}/${CONSTANTS.GET_FORMS_APP}`,payload,options).then(response => {
        console.log(response)
        return {
            list: response.data.data,
        };
    });
}

export default {
    getListRoles,
    getListCompanies,
    getListCompaniesByUser,
    getListSections,
    getListFamilies,
    getListUsers,
    getListAttributes,
    getListInputs,
    saveAttribute,
    updateAttribute,
    deleteAttribute,
    getListForms,
    getListFamiliesBySection,
    saveForm,
    saveSection,
    saveFamily,
    getListAttributesByFamily,
    getListApiIcons,
    getAttribute,
    getUser,
    updateUser,
    deleteUser,
    getSection,
    updateSection,
    deleteSection,
    getFamily,
    updateFamily,
    deleteFamily,
    getForm,
    deleteForm,
    updateForm,
    getListFormsValue,
    exportRow,
    exportAllRow,
    deleteFormValue
};