<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
        </base-header>
        <div class="container-fluid mt-5">
            <card body-classes="px-0 pb-1" footer-classes="pb-2">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-0">Listado de atributos</h3>
                        </div>
                        <div class="col text-right">
                            <base-button type="primary" icon size="sm" @click="createAttribute">
                                <i class="fas fa fa-plus mr-2"></i>
                                <span class="btn-inner--text">Agregar Atributo</span>
                            </base-button>
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <base-table class="table align-items-center table-flush" tbody-classes="list" :data="attributes"
                        :thead-classes="'thead-light'">
                        <template slot="columns">
                            <th>ID</th>
                            <th>Título</th>
                            <th>Ícono</th>
                            <th>Tipo</th>
                            <th>Requerido</th>
                            <th>Familia</th>
                            <th>Orden</th>
                            <th>Acciones</th>
                        </template>

                        <template slot-scope="{ row }">
                            <td>
                                {{ row.id }}
                            </td>
                            <td>
                                {{ row.title }}
                            </td>
                            <td>
                                <ion-icon :name="row.icon" size="large"></ion-icon>
                            </td>
                            <td>
                                {{ (row.input != null) ? row.input.description :  ''  }}
                            </td>
                            <td>
                                {{ row.required ? 'Si' : 'No' }}
                            </td>
                            <td>
                                {{ (row.family != null) ? row.family.title :  '' }}
                            </td>
                            <td>
                                {{ row.orden }}
                            </td>
                            <td>
                                <a style="cursor: pointer" @click="editAttribute(row.id)" class="mr-2">
                                    <i class="fas fa-user-edit"></i>
                                </a>
                                <a style="cursor: pointer" @click="openModal(row.id)">
                                    <i class="fas fa-trash"></i>
                                </a>
                            </td>
                        </template>
                    </base-table>
                </div>

                <!--Classic modal-->
                <modal :show.sync="showAlert">
                <h3 slot="header" class="modal-title">Eliminar</h3>
                <p>¿Estás seguro que quieres eliminar el atributo?</p>
                <template slot="footer">
                    <base-button type="primary" @click="deleteAttribute">Aceptar</base-button>
                    <base-button type="link" class="ml-auto" @click="showAlert = false">Cancelar</base-button>
                </template>

                </modal>

                <div class="card-footer d-flex justify-content-end">
                    <base-pagination :total="totalAttributes"></base-pagination>
                </div>
            </card>
        </div>
    </div>
</template>
<script>

import router from "@/router";
import lisServices from '../../store/services/getList-service';

export default {
    data() {
        return {
            attributes: [],
            showAlert: false,
            attributeId: null,
            totalAttributes: 1
        };
    },
    methods: {
        openModal(id) {
            this.showAlert=true;
            this.attributeId = id;
        },
        async getListAttributes() {
            let response = await lisServices.getListAttributes();
            console.log('Attributes', response);
            this.attributes = response?.list;
            // this.totalAttributes = this.attributes.length;
            console.log(this.attributes);
        },
        createAttribute() {
            router.push({ path: '/modules/create-attribute' });
            // this.$router.push('/dashboard')
            // this.$notify({
            //     type: "danger",
            //     message: "This is a PRO feature.",
            // });
        },
        editAttribute(id) {
            console.log(id)
            router.push({ path: '/modules/edit-attribute/'+id });
            // this.$router.push('/dashboard')
            
        },
        async deleteAttribute(){
            let response = await lisServices.deleteAttribute(this.attributeId);
            console.log('Attributes', response);
            this.showAlert = false;
            if(response.status){
                this.getListAttributes();
                this.$notify({
                    type: "success",
                    message: "¡Operación ejecutada correctamente!",
                });
            }else{
                this.$notify({
                    type: "danger",
                    message: "Error en la operación",
                });
            }
           
        }
    },
    mounted() {
        this.getListAttributes();
    },
};
</script>
<style></style>
  