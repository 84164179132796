<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
        </base-header>
        <div class="container-fluid mt-5">
            <card body-classes="px-0 pb-1" footer-classes="pb-2">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-0">Listado de Formularios</h3>
                        </div>
                        <div class="col text-right">
                            <base-button type="primary" icon size="sm" @click="createForm">
                                <i class="fas fa fa-plus mr-2"></i>
                                <span class="btn-inner--text">Agregar Formulario</span>
                            </base-button>
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <base-table class="table align-items-center table-flush" tbody-classes="list" :data="forms"
                        :thead-classes="'thead-light'">
                        <template slot="columns">
                            <th>ID</th>
                            <th>Título</th>
                            <th>Logo</th>
                            <th>Acciones</th>
                        </template>

                        <template slot-scope="{ row }">
                            <td>
                                {{ row.id }}
                            </td>
                            <td>
                                {{ row.title }}
                            </td>
                            <td>
                                <ion-icon :name="row.logo" size="large"></ion-icon>
                            </td>
                            <td>
                                <a style="cursor: pointer" @click="editForm(row.id)" class="mr-2">
                                    <i class="fas fa-user-edit"></i>
                                </a>
                                <a style="cursor: pointer" @click="openModal(row.id)">
                                    <i class="fas fa-trash"></i>
                                </a>
                            </td>
                        </template>
                    </base-table>
                </div>
                <!--Classic modal-->
                <modal :show.sync="showAlert">
                    <h3 slot="header" class="modal-title">Eliminar</h3>
                    <p>¿Estás seguro que quieres eliminar el registro?</p>
                    <template slot="footer">
                        <base-button type="primary" @click="deleteForm">Aceptar</base-button>
                        <base-button type="link" class="ml-auto" @click="showAlert = false">Cancelar</base-button>
                    </template>

                </modal>
                <div class="card-footer d-flex justify-content-end">
                    <base-pagination total="1"></base-pagination>
                </div>
            </card>
        </div>
    </div>
</template>
<script>

import router from "@/router";
import lisServices from '../../store/services/getList-service';

export default {
    data() {
        return {
            forms: [],
            showAlert: false,
            formId: null
        };
    },
    methods: {
        openModal(id) {
            this.showAlert=true;
            this.formId = id;
        },
        async deleteForm(){
            let response = await lisServices.deleteForm(this.formId);
            console.log('Form', response);
            this.showAlert = false;
            if(response.status){
                this.getListForms();
                this.$notify({
                    type: "success",
                    message: "¡Operación ejecutada correctamente!",
                });
            }else{
                this.$notify({
                    type: "danger",
                    message: "Error en la operación",
                });
            }
           
        },
        editForm(id) {
            console.log(id)
            router.push({ path: '/modules/edit-form/' + id });
            // this.$router.push('/dashboard')

        },
        async getListForms() {
            let response = await lisServices.getListForms();
            console.log('Forms', response);
            this.forms = response.list;
            console.log(this.forms);
        },
        createForm() {
            router.push({ path: '/modules/create-form' });
        },
    },
    mounted() {
        this.getListForms();
    },
};
</script>
<style></style>
  