<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
        </base-header>
        <div class="container-fluid mt-5">

            <div class="card">
                <div class="content card-header ">
                    <h1>Editar Formulario</h1>
                    <base-button type="primary" icon size="sm" @click="$router.back()">
                        <i class="fas fa fa-arrow-left mr-2"></i>
                        <span class="btn-inner--text">Regresar</span>
                    </base-button>
                </div>
                <div class="card-body">
                    <form role="form" @submit.prevent="handleSubmit()">

                        <div class="row">
                            <div class="col-lg-6">
                                <base-input label="Título" placeholder="" v-model="title" />
                                <validation-error :errors="apiValidationErrors.title" />
                            </div>
                            <div class="col-lg-5">
                                <!-- <base-input label="Logo" placeholder="" v-model="logo" /> -->
                                <base-input label="Logo">
                                            <select v-model="logo" class="form-control">
                                                <option v-for="option in iconsOptions" :key="option.name"
                                                    :label="option.name"  :value="option.name">            
                                                </option>
                                            </select>
                                        </base-input>
                                <!-- <validation-error :errors="apiValidationErrors.email" /> -->
                            </div>
                            <div class="col-lg-1">
                                <ion-icon :name="logo" size="large" class="icon-select"></ion-icon>
                            </div>
                            <div class="col-lg-12">
                                <h3>Agregar usuarios al formulario</h3>
                                <div class="row">
                                    <div class="col-lg-3">
                                        <base-input label="Usuario">
                                            <select v-model="user" class="form-control" @change="selectUserChange">
                                                <option v-for="option in usersOptions" :key="option.id"
                                                    :value="option">
                                                    {{ option.id }} - {{ option.attributes.name }}
                                                </option>
                                            </select>
                                        </base-input>
                                        <validation-error :errors="apiValidationErrors.user" />
                                    </div>

                                    <div class="col-lg-6">
                                        <base-input label="Empresas">
                                            <select v-model="companies" class="form-control" multiple="">
                                                <option v-for="option in companiesOptions" :key="option.company_id"
                                                    :value="option.company">
                                                    {{ option.id }} - {{ option.company.description }}
                                                </option>
                                            </select>
                                        </base-input>
                                        <validation-error :errors="apiValidationErrors.companies" />
                                    </div>
                                    <div class="col-lg-2">
                                        <div class="text-center pt-5">
                                            <base-button icon type="primary" :disabled="isDisabledUserButton" @click="saveUser">
                                                <span class="btn-inner--icon"><i class="ni ni-check-bold"></i></span>
                                            </base-button>
                                        </div>

                                    </div>
                                </div>


                            </div>

                            <div class="col-lg-12">
                                <h3>Agregar secciones al formulario</h3>
                                <div class="row">
                                    <div class="col-lg-3">
                                        <base-input label="Sección">
                                            <select v-model="section" class="form-control" @change="selectSectionChange">
                                                <option v-for="option in sectionsOptions" :key="option.id"
                                                    :value="option">
                                                    {{ option.id }} - {{ option.title }}
                                                </option>
                                            </select>
                                        </base-input>
                                        <validation-error :errors="apiValidationErrors.families" />
                                    </div>

                                    <div class="col-lg-3">
                                        <base-input label="Familias">
                                            <select v-model="family" class="form-control" @change="selectFamilyChange">
                                                <option v-for="option in familiesOptions" :key="option.id"
                                                    :label="option.title" :value="option">
                                                    {{ option.id }} - {{ option.title }}
                                                </option>
                                            </select>
                                        </base-input>
                                        <validation-error :errors="apiValidationErrors.family" />
                                    </div>

                                    <div class="col-lg-4">
                                        <base-input label="Atributos">
                                            <select v-model="attributes" class="form-control" multiple="">
                                                <option v-for="option in attributesOptions" :key="option.id"
                                                     :value="option">
                                                    {{ option.id }} - {{ option.title }}
                                                </option>
                                            </select>
                                        </base-input>
                                        <validation-error :errors="apiValidationErrors.attributes" />
                                    </div>

                                    <div class="col-lg-2">
                                        <div class="text-center pt-5">
                                            <base-button icon type="primary" :disabled="isDisabled" @click="saveSection">
                                                <span class="btn-inner--icon"><i class="ni ni-check-bold"></i></span>
                                            </base-button>
                                        </div>

                                    </div>
                                </div>


                            </div>

                            <div class="col-lg-6">
                                <div class="table-responsive">
                                    <h3>Usuarios</h3>
                                    <base-table class="table align-items-center table-flush" tbody-classes="list"
                                        :data="users" :thead-classes="'thead-light'">
                                        <template slot="columns">
                                            <th>ID</th>
                                            <th>Usuario</th>
                                            <th>Perfil</th>
                                            <th></th>
                                        </template>

                                        <template slot-scope="{ row }">
                                            <td>
                                                {{ row.id }}
                                            </td>
                                            <td>
                                                {{ row.name }}
                                            </td>
                                            <td>
                                                {{ row.role.description }}
                                            </td>
                                            <td>
                                                <a style="cursor: pointer" @click="deleteUser(index)">
                                                    <i class="fas fa-trash"></i>
                                                </a>
                                            </td>
                                        </template>
                                    </base-table>
                                </div>
                            </div>

                            <div class="col-lg-6">
                                <div class="table-responsive">
                                    <h3>Secciones</h3>
                                    <base-table class="table align-items-center table-flush" tbody-classes="list"
                                        :data="sections" :thead-classes="'thead-light'">
                                        <template slot="columns">
                                            <th>ID</th>
                                            <th>Sección</th>
                                            <th>Orden</th>
                                            <th>Familias</th>
                                            <th></th>
                                        </template>

                                        <template slot-scope="{ row,index }">
                                            <td>
                                                {{ row.id }}
                                            </td>
                                            <td>
                                                {{ row.title }}
                                            </td>
                                            <td>
                                                {{ row.orden }}
                                            </td>
                                            <td>
                                                {{ row.families.length }}
                                            </td>
                                            <td>
                                                <a style="cursor: pointer" @click="deleteSection(index)">
                                                    <i class="fas fa-trash"></i>
                                                </a>
                                            </td>
                                        </template>
                                    </base-table>
                                </div>
                            </div>
                        </div>

                        <div class="text-center">
                            <base-button type="primary" @click="previewForm" class="my-4">Previsualizar</base-button>
                            <base-button type="primary" native-type="submit" class="my-4">Guardar</base-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>

import router from "@/router";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import lisServices from '../../store/services/getList-service';
import { Select, Option } from 'element-ui'

export default {
    components: {
        ValidationError,
        [Select.name]: Select,
        [Option.name]: Option
    },
    created() {
        console.log('Component mounted',this.$route.params.id)
        this.getLisUsers();
        this.getSections();
        this.getFormData(this.$route.params.id);
        this.getLisApiIcons();
    },
    mixins: [formMixin],
    data() {
        return {
            form: null,
            title: null,
            logo: null,
            family: null,
            families: [],
            user: null,
            companies: [],
            sections: [],
            section: null,
            attributes: [],
            attributesOptions: [],
            familiesOptions: [],
            sectionsOptions: [],
            usersOptions: [],
            companiesOptions: [],
            iconsOptions: [],
            users: [],
        };
    },
    computed: {
        isDisabled() {
            return (this.section != null && this.family != null && this.attributes.length > 0) ? false : true;
        },
        isDisabledUserButton(){
            return (this.user != null && this.companies.length > 0) ? false : true;
        }
    },
    methods: {
        async getFormData(formId) {
            let response = await lisServices.getForm(formId);
            this.form = response.list;
            console.log('FORM',this.form);
            this.title = this.form.title??null;
            this.logo = this.form.logo??null;
            this.sections = (this.form?.sections?.length > 0) ?this.form?.sections : [];
            this.users = (this.form?.users?.length > 0) ?this.form?.users : [];
           
        },
        // async getForm() {
        //     this.form = await { ...this.$store.getters["profile/getForm"] };
        //     console.log('FORM', this.form);
        //     this.title = this.form.title??null;
        //     this.logo = this.form.logo??null;
        //     this.sections = (this.form?.sections.length > 0) ?this.form?.sections : [];
        //     this.users = (this.form?.users.length > 0) ?this.form?.users : [];
        // },
        showScore(score) {
            this.scors = score;
        },
        async getSections() {
            let response = await lisServices.getListSections();
            this.sectionsOptions = response.list;
            console.log('Sections', this.sectionsOptions);
        },
        async getLisUsers() {
            let responseUser = await lisServices.getListUsers();
            this.usersOptions = responseUser.list;
            console.log('Users', this.usersOptions);
        },
        async getLisCompaniesByUser(userId) {
            let responseUser = await lisServices.getListCompaniesByUser(userId);
            this.companiesOptions = responseUser.list;
            console.log('Comanies', this.companiesOptions);
        },
        async getLisApiIcons() {
            let responseUser = await lisServices.getListApiIcons();
            this.iconsOptions = responseUser.list;
            console.log('Icons', this.iconsOptions);
        },
        deleteUser(index) {
            console.log(index)
            this.users.splice(index, 1);
        },
        async handleSubmit() {

            const data = {
                id: this.$route.params.id,
                title: this.title,
                logo: this.logo,
                sections: this.sections,
                users: this.users
            };

            console.log('QUERY ->', data);

            try {
                let response = await lisServices.updateForm(data);
                console.log(response);

                if (response.status) {
                    this.$notify({
                        type: "success",
                        message: "¡Operación ejecutada correctamente!",
                    });
                    await this.$store.dispatch("profile/previewForm", null);
                    router.push({ path: '/modules/forms' });

                } else {
                    this.$notify({
                        type: "danger",
                        message: response.message,
                    });
                }

            } catch (error) {
                console.log(error)
                this.$notify({
                    type: "danger",
                    message: "¡Error en la ejecución de la operación!",
                });
            }
        },
        selectFamilyChange() {
            console.log('Select Family', this.family)
            this.getAttributesByFamily(this.family.id)

        },
        selectSectionChange() {
            console.log('Select Section', this.section)
            this.getFamiliesBySection(this.section.id)
        },
        selectUserChange() {
            console.log('Select User', this.user)
            this.getLisCompaniesByUser(this.user.id);
        },
        async previewForm() {

            const body = {
                id: this.$route.params.id,
                title: this.title,
                logo: this.logo,
                sections: this.sections,
                users: this.users
            }

            await this.$store.dispatch("profile/previewForm", body);
            this.$router.push({ name: 'Formulario Preview' });
            // await this.$store.dispatch("previewForm", body);
        },
        async getFamiliesBySection(id) {
            let response = await lisServices.getListFamiliesBySection(id);

            this.familiesOptions = response.list;
            console.log('Families', this.familiesOptions);

        },
        async getAttributesByFamily(id) {
            let response = await lisServices.getListAttributesByFamily(id);

            this.attributesOptions = response.list;
            console.log('Attributes', this.attributesOptions);

        },
        saveSection() {

            let exist = this.sections.find(p => p.id == this.section.id);

            if(exist != undefined){
                this.family.attributes = this.attributes;
                exist.families.push(this.family)
            }else{
                this.family.attributes = this.attributes;
                this.families.push(this.family)
                this.section.families = this.families;
                this.sections.push(this.section);
            }
            
            console.log(exist);

            this.section = null;
            this.family = null;
            this.attributes = [];
            this.attributesOptions = [];
            this.families = [];
            this.familiesOptions = [];

        },
        deleteSection(index) {
            console.log(index)
            this.sections.splice(index, 1);
        },
        saveUser(){

            const body = {
                id: this.user.id,
                name: this.user.attributes.name,
                email: this.user.attributes.email,
                role: this.user.attributes.role,
                companies: this.companies,             
            }
            console.log(body);
            this.users.push(body);

            this.user = null;
            this.companiesOptions = [];
        }
    },
};
</script>
<style>
.content {
    display: inline-flex !important;
    justify-content: space-between !important;
}
.icon-select {
    margin-top: 30px;
}
</style>
      