<template>
  <div>
    <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
    </base-header>
    <div class="container-fluid mt-5">

      <div class="card">
        <div class="content card-header">
          <h1>Registrar usuario</h1>
          <base-button type="primary" icon size="sm" @click="$router.back()">
            <i class="fas fa fa-arrow-left mr-2"></i>
            <span class="btn-inner--text">Regresar</span>
          </base-button>
        </div>
        <div class="card-body">
          <form role="form" @submit.prevent="handleSubmit()">
            <div class="row">
              <div class="col-lg-6">
                <base-input label="Nombre" placeholder="" v-model="name" />
                <validation-error :errors="apiValidationErrors.name" />
              </div>
              <div class="col-lg-6">
                <base-input label="Correo" placeholder="name@example.com" v-model="email" />
                <validation-error :errors="apiValidationErrors.email" />
              </div>
              <div class="col-lg-6">
                <base-input label="Tipo usuario">
                  <select v-model="role" class="form-control">
                    <option v-for="option in selectRoles" :key="option.id" :value="option.id">
                    {{ option.id }} - {{ option.description }}
                    </option>
                  </select>
                </base-input>

                <validation-error :errors="apiValidationErrors.role" />
              </div>
              <div class="col-lg-6">
                <base-input label="Empresas">
                  <select v-model="companies" multiple="" class="form-control">
                    <option v-for="option in selectCompanies" :key="option.id"
                      :value="option.id">
                      {{ option.id }} - {{ option.description }}
                    </option>
                  </select>
                </base-input>
                <validation-error :errors="apiValidationErrors.companies" />
              </div>
              <div class="col-lg-6">
                <base-input label="Contraseña" placeholder="name@example.com" v-model="password" type="password" />
                <password class="mb-3" v-model="password" :strength-meter-only="true" @score="showScore"
                  :showStrengthMeter="false" />
                <validation-error :errors="apiValidationErrors.password" />
              </div>
              <div class="col-lg-6">
                <base-input label="Confirmar contraseña" placeholder="name@example.com" v-model="password_confirmation"
                  type="password" />
                <validation-error :errors="apiValidationErrors.password_confirmation" />
              </div>
              <div class="col-lg-12">
                <div class="text-muted font-italic">
                  <small>password strength:
                    <template v-if="scors === 0">
                      <span class="text-danger font-weight-700"> Muy fácil </span>
                    </template>

                    <template v-if="scors === 1">
                      <span class="text-danger font-weight-700"> Fácil </span>
                    </template>

                    <template v-if="scors === 2">
                      <span class="text-warning font-weight-700"> Aceptable </span>
                    </template>

                    <template v-if="scors === 3">
                      <span class="text-success font-weight-700"> Difícil </span>
                    </template>

                    <template v-if="scors === 4">
                      <span class="text-success font-weight-700">
                        Muy difícil
                      </span>
                    </template>
                  </small>
                </div>
              </div>
            </div>

            <div class="text-center">
              <base-button type="primary" native-type="submit" class="my-4">Guardar</base-button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>
 
<script>
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import Password from "vue-password-strength-meter";
import lisServices from '../../store/services/getList-service';
import { Select, Option } from 'element-ui'

export default {
  components: {
    ValidationError,
    Password,
    [Select.name]: Select,
    [Option.name]: Option
  },
  mounted() {
    console.log('Component mounted')
    this.getRoles();
    this.getCompanies();
  },
  mixins: [formMixin],
  data() {
    return {
      name: null,
      boolean: false,
      email: null,
      password: null,
      password_confirmation: null,
      scors: "",
      role: null,
      companies: [],
      selectRoles: [],
      selectCompanies: [],
      options: [
        { name: 'Vue.js', language: 'JavaScript' },
        { name: 'Rails', language: 'Ruby' },
        { name: 'Sinatra', language: 'Ruby' },
        { name: 'Laravel', language: 'PHP', $isDisabled: true },
        { name: 'Phoenix', language: 'Elixir' }
      ]
    };
  },

  methods: {
    showScore(score) {
      this.scors = score;
    },
    async getRoles() {
      let response = await lisServices.getListRoles();
      console.log(response);

      this.selectRoles = response.list;
      console.log('Roles', this.selectRoles);
    },
    async getCompanies() {
      let response = await lisServices.getListCompanies();
      console.log('Companies', response);

      this.selectCompanies = response.list;
      console.log(this.selectCompanies);
    },
    async handleSubmit() {
      // if (!this.boolean) {
      //   console.log("ceva");
      //   await this.$notify({
      //     type: "danger",
      //     message: "You need to agree with our terms and conditions.",
      //   });
      //   return;
      // }

      const user = {
        data: {
          type: "token",
          attributes: {
            name: this.name,
            email: this.email,
            password: this.password,
            password_confirmation: this.password_confirmation,
            role: this.role,
            companies: this.companies,
          },
        },
      };

      const requestOptions = {
        headers: {
          Accept: "application/vnd.api+json",
          "Content-Type": "application/vnd.api+json",
        },
      };

      try {
        await this.$store.dispatch("register", { user, requestOptions });
        this.$notify({
          type: "success",
          message: "¡Usuario registrado correctamente!",
        });
      } catch (error) {
        this.$notify({
          type: "danger",
          message: "¡Error en la ejecución de la operación!",
        });
        this.setApiValidation(error.response.data.errors);
      }
    },
  },
};
</script>
<style>
.content {
  display: inline-flex !important;
  justify-content: space-between !important;
}
</style>
    