<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
        </base-header>
        <div class="container-fluid mt-5">

            <div class="card">
                <div class="content card-header ">
                    <h1>Visualizar Formulario</h1>
                    <base-button type="primary" icon size="sm" @click="$router.go(-1)">
                        <i class="fas fa fa-arrow-left mr-2"></i>
                        <span class="btn-inner--text">Regresar</span>
                    </base-button>
                </div>
                <div class="card-body">
                    <form role="form" @submit.prevent="handleSubmit()">

                        <tabs v-if="tabsData.length > 0" type="info" :tabs="tabsData" />

                        <div class="text-center">
                            <base-button type="primary" native-type="submit" class="my-4">Guardar</base-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>

import router from "@/router";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import lisServices from '../../store/services/getList-service';
import { Select, Option } from 'element-ui'
// import Tabs from '@/components/Tabs/Tabs.vue';
export default {
    data() {
        return {
            title: null,
            logo: null,
            families: [],
            users: [],
            familiesOptions: [],
            usersOptions: [],
            form: null,
            tabsData: []
        };
    },
    components: {
        // Tabs,
        // ValidationError,
        [Select.name]: Select,
        [Option.name]: Option
    },
    mounted() {
        this.getForm();
        // this.getFamilies();
        // this.getLisUsers();

    },
    mixins: [formMixin],

    methods: {
        showScore(score) {
            this.scors = score;
        },
        async getFamilies() {
            let response = await lisServices.getListFamilies();
            console.log(response);

            this.familiesOptions = response.list;
            console.log('Families', this.familiesOptions);
        },
        async getLisUsers() {
            let response = await lisServices.getListUsers();
            console.log('Users', response);

            this.usersOptions = response.list;
            console.log(this.usersOptions);
        },
        async handleSubmit() {

            const data = {
                title: this.form.title,
                logo: this.form.logo,
                sections: this.form.sections,
                users: this.form.users
            };

            console.log('QUERY ->', data);

            try {
                let response = await lisServices.saveForm(data);
                console.log(response);

                if (response.success) {
                    this.$notify({
                        type: "success",
                        message: "¡Operación ejecutada correctamente!",
                    });
                    await this.$store.dispatch("profile/previewForm", null);
                    router.push({ path: '/modules/forms' });

                } else {
                    this.$notify({
                        type: "danger",
                        message: response.message,
                    });
                }

            } catch (error) {
                console.log(error)
                this.$notify({
                    type: "danger",
                    message: "¡Error en la ejecución de la operación!",
                });
            }
        },
        async getForm() {
            this.form = await { ...this.$store.getters["profile/getForm"] };
            console.log('FORM', this.form);
            this.tabsData = this.form.sections;
        }
    },
};
</script>
<style>
.content {
    display: inline-flex !important;
    justify-content: space-between !important;
}
</style>
      