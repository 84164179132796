<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
        </base-header>
        <div class="container-fluid mt-5">

            <div class="card">
                <div class="content card-header">
                    <h1>Editar Atributo</h1>
                    <base-button type="primary" icon size="sm" @click="$router.back()">
                        <i class="fas fa fa-arrow-left mr-2"></i>
                        <span class="btn-inner--text">Regresar</span>
                    </base-button>
                </div>
                <div class="card-body">
                    <form role="form" @submit.prevent="handleSubmit()">

                        <div class="row">
                            <div class="col-lg-6">
                                <base-input label="Título" placeholder="" v-model="title" />
                                <validation-error :errors="apiValidationErrors.title" />
                            </div>
                            <div class="col-lg-5">
                                <base-input label="Ícono">
                                    <select v-model="icon" class="form-control">
                                        <option v-for="option in iconsOptions" :key="option.name"
                                            :label="option.name"  :value="option.name">            
                                        </option>
                                    </select>
                                </base-input>
                                <!-- <base-input label="Ícono" placeholder="" v-model="icon" /> -->
                                <!-- <validation-error :errors="apiValidationErrors.email" /> -->
                            </div>
                            <div class="col-lg-1">
                                <ion-icon :name="icon" size="large" class="icon-select"></ion-icon>
                            </div>

                            <div class="col-lg-6">
                                <base-input label="Tipo">
                                    <select v-model="typeInput" class="form-control">
                                        <option v-for="option in types" :key="option.id"
                                            :value="option.id">
                                            {{ option.id }} - {{ option.description }}
                                        </option>
                                    </select>
                                </base-input>
                                <validation-error :errors="apiValidationErrors.typeInput" />
                            </div>

                            <div class="col-lg-6">
                                <base-input label="Obligatorio">
                                    <base-switch :value="obligatorio" class="ml-3" on-text="Si" off-text="No"
                                        @change="handleSwitchChange"></base-switch>
                                </base-input>
                                <!-- <validation-error :errors="apiValidationErrors.obligatorio" /> -->
                            </div>

                            <div class="col-lg-6">
                                <base-input label="Valor del input">
                                    <textarea class="form-control" rows="5" v-model="contenido"></textarea>
                                </base-input>
                                <!-- <validation-error :errors="apiValidationErrors.contenido" /> -->
                            </div>

                            <div class="col-lg-6">
                                <base-input label="Familia">
                                    <select v-model="family" class="form-control">
                                        <option v-for="option in families" :key="option.id" 
                                            :value="option.id">
                                            {{ option.id }} - {{ option.title }}
                                        </option>
                                    </select>
                                </base-input>
                                <validation-error :errors="apiValidationErrors.family" />
                            </div>

                            <div class="col-lg-6">
                                <base-input label="Orden Atributo">
                                    <select v-model="orden" class="form-control">
                                        <option v-for="option in ordenOptions" :key="option" :label="option"
                                            :value="option">
                                        </option>
                                    </select>
                                </base-input>
                                <validation-error :errors="apiValidationErrors.orden" />
                            </div>

                            <div class="col-lg-6">
                                <base-input label="Columna Dashboard">
                                    <select v-model="columns" class="form-control">
                                        <option v-for="option in columnOptions" :key="option" :label="option"
                                            :value="option">
                                        </option>
                                    </select>
                                </base-input>
                                <validation-error :errors="apiValidationErrors.columns" />
                            </div>


                            <div class="col-lg-6">
                                <base-input label="Subtotable">
                                    <base-switch class="ml-3" on-text="" off-text="" :value="subtotable"
                                        @change="handleSwitchChangeSubtotable"></base-switch>
                                </base-input>
                                <validation-error :errors="apiValidationErrors.obligatorio" />
                            </div>
                        </div>

                        <div class="text-center">
                            <base-button type="primary" native-type="submit" class="my-4">Guardar</base-button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
   
<script>

import router from "@/router";
import ValidationError from "@/components/ValidationError.vue";
import formMixin from "@/mixins/form-mixin";
import lisServices from '../../store/services/getList-service';
import { Select, Option } from 'element-ui'

export default {
    components: {
        ValidationError,
        [Select.name]: Select,
        [Option.name]: Option
    },
    mounted() {
        console.log('Component mounted',this.$route.params.id)
        this.getFamilies();
        this.getInputs();
        this.getLisApiIcons();
        this.getAttribute(this.$route.params.id);
    },
    mixins: [formMixin],
    data() {
        return {
            title: null,
            typeInput: null,
            obligatorio: false,
            contenido: null,
            orden: null,
            icon: null,
            family: null,
            subtotable: false,
            columns: null,
            families: [],
            types: [],
            ordenOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
            columnOptions: [1, 2, 3, 4],
            attribute: null,
            iconsOptions: []
        };
    },

    methods: {
        showScore(score) {
            this.scors = score;
        },
        async getFamilies() {
            let response = await lisServices.getListFamilies();
            console.log(response);

            this.families = response.list;
            console.log('Families', this.families);
        },
        async getInputs() {
            let response = await lisServices.getListInputs();
            console.log('Inputs', response);

            this.types = response.list;
            console.log(this.types);
        },
        async getAttribute(attributeId) {
            let response = await lisServices.getAttribute(attributeId);
            this.attribute = response.list;
            console.log(this.attribute);
            this.title = this.attribute.title;
            this.obligatorio = this.attribute.required;
            this.typeInput = this.attribute.input_type_id;
            this.orden = this.attribute.orden;
            this.contenido = this.attribute.value;
            this.icon = this.attribute.icon;
            this.family = this.attribute.family_id;
            this.subtotable = this.attribute.subtotable;
            this.columns = this.attribute.column;
        },
        async handleSubmit() {

            const data = {
                id : parseInt(this.$route.params.id),
                title: this.title,
                typeInput: this.typeInput,
                obligatorio: this.obligatorio,
                contenido: this.contenido,
                orden: this.orden,
                icon: this.icon,
                family: this.family,
                subtotable: this.subtotable,
                columns: this.columns,
            };

            try {
                let response = await lisServices.updateAttribute(data);
                console.log(response);
                if (response.status) {
                    this.$notify({
                        type: "success",
                        message: "¡Operación ejecutada correctamente!",
                    });
                    router.push({ path: '/modules/attributes' });
                } else {
                    this.$notify({
                        type: "danger",
                        message: response.message,
                    });
                }

            } catch (error) {
                console.log(error)
                this.$notify({
                    type: "danger",
                    message: "¡Error en la ejecución de la operación!",
                });
            }
        },
        handleSwitchChange(newValue) {

            this.obligatorio = newValue.target.checked;
            console.log(this.obligatorio)

        },
        handleSwitchChangeSubtotable(newValue) {

            this.subtotable = newValue.target.checked;
            console.log(this.subtotable)

        },
        async getLisApiIcons() {
            let responseUser = await lisServices.getListApiIcons();
            this.iconsOptions = responseUser.list;
            console.log('Icons', this.iconsOptions);
        },
    },
};
</script>
<style>
.content {
    display: inline-flex !important;
    justify-content: space-between !important;
}

.icon-select {
    margin-top: 30px;
}
</style>
      