export const CONSTANTS = {
    //GET
    GET_LIST_ROLES : 'roles',
    GET_LIST_COMPANIES : 'companies',
    GET_LIST_SECTIONS : 'sections',
    SAVE_SECTIONS : 'save-section',
    GET_LIST_FAMILIES : 'families',
    GET_LIST_USERS : 'users',
    GET_LIST_ATTRIBUTES : 'attributes',
    GET_LIST_INPUTS : 'inputs',
    GET_LIST_FORMS : 'forms',
    GET_LIST_FAMILIES_BY_SECTION : 'families-by-section',
    GET_LIST_ATTRIBUTES_BY_FAMILY : 'attributes',
    GET_LIST_COMPANIES_BY_USER : 'companies-user',
    GET_LIST_API_ICONS : 'get-icons',
    GET_ATTRIBUTE : 'attribute',
    GET_USER : 'user',
    GET_SECTION : 'section',
    GET_FAMILY : 'family',
    GET_FORM : 'form',
    GET_FORMS_APP : 'list-forms-app',
    EXPORT_FORM_ATTRIBUTE : 'export-value',
    EXPORT_FORM_ALL_ATTRIBUTE : 'export-form-all-value',
    //STORE
    SAVE_ATTRBUTES : 'save-attribute',
    SAVE_FORM : 'save-form',
    SAVE_SECTION : 'save-section',
    SAVE_FAMILY : 'save-family',
    //UPDATE
    UPDATE_ATTRBUTE : 'update-attribute-value',
    UPDATE_USER : 'update-user',
    UPDATE_SECTION : 'update-section',
    UPDATE_FAMILY : 'update-family',
    UPDATE_FORM : 'update-form',
    //DELETE
    DELETE_ATTRIBUTE : 'delete-attribute-value',
    DELETE_USER : 'delete-user',
    DELETE_SECTION : 'delete-section',
    DELETE_FAMILY : 'delete-family',
    DELETE_FORM : 'delete-form',
    DELETE_FORM_VALUE : 'delete-form-value',
}