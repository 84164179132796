import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
// Example pages
import UserProfile from "@/views/Examples/UserProfile.vue";
import ListUserPage from "@/views/Examples/UserManagement/ListUserPage.vue";
import ListSection from "./views/Section/Section.vue";
import ListFamily from "./views/Family/Family.vue";
import ListUsers from "./views/Users/Users.vue";
import CreateUser from './views/Users/Create.vue';
import EditUser from './views/Users/Edit.vue';
import ListAttributes from './views/Attributes/Attributes.vue';
import CreateAttribute from './views/Attributes/Create.vue';
import EditAttribute from './views/Attributes/Edit.vue';
import ListForms from './views/Forms/Form.vue';
import CreateForm from './views/Forms/Create.vue';
import EditForm from './views/Forms/Edit.vue';
import PreviewForm from './views/Forms/Preview.vue';
import PreviewFormEdit from './views/Forms/PreviewEdit.vue';
import CreateSection from './views/Section/Create.vue';
import EditSection from './views/Section/Edit.vue';
import CreateFamily from './views/Family/Create.vue';
import EditFamily from './views/Family/Edit.vue';
import ListFormsApps from './views/Forms/ListFormApp.vue';

const PasswordReset = () =>
  import(/* webpackChunkName: "password" */ "@/views/Password/Reset.vue");
const PasswordEmail = () =>
  import(/* webpackChunkName: "password" */ "@/views/Password/Email.vue");
Vue.use(Router)

//import middleware
import auth from "@/middleware/auth";
import guest from "@/middleware/guest";

const router = new Router({
  linkExactActiveClass: 'active',
  mode: 'hash',
  // mode: 'history',
  routes: [
    {
      path: '/',
      redirect: 'modules/users',
      component: DashboardLayout,
      children: [
        {
          path: '/dashboard',
          name: 'dashboard',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue'),
          meta: { middleware: auth },
        },
        // {
        //   path: '/main',
        //   name: 'main',
        //   // route level code-splitting
        //   // this generates a separate chunk (about.[hash].js) for this route
        //   // which is lazy-loaded when the route is visited.
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Main/Main.vue'),
        //   meta: { middleware: auth },
        // },
        {
          path: '/setting',
          name: 'Principal',
          // route level code-splitting
          // this generates a separate chunk (about.[hash].js) for this route
          // which is lazy-loaded when the route is visited.
          component: () => import(/* webpackChunkName: "demo" */ './views/Setting/Setting.vue'),
          meta: { middleware: auth },
        },
        // {
        //   path: '/list-forms',
        //   name: 'Formularios',
        //   // route level code-splitting
        //   // this generates a separate chunk (about.[hash].js) for this route
        //   // which is lazy-loaded when the route is visited.
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Forms/Form.vue'),
        //   meta: { middleware: auth },
        // },
        {
          path: '/icons',
          name: 'icons',
          component: () => import(/* webpackChunkName: "demo" */ './views/Icons.vue')
        },
        {
          path: '/profile',
          name: 'profile',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        },
        {
          path: '/maps',
          name: 'maps',
          component: () => import(/* webpackChunkName: "demo" */ './views/Maps.vue')
        },
        {
          path: '/tables',
          name: 'tables',
          component: () => import(/* webpackChunkName: "demo" */ './views/Tables.vue')
        }
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue'),
          meta: { middleware: guest }
        },
        {
          path: '/register',
          name: 'register',
          component: () => import(/* webpackChunkName: "demo" */ './views/Register.vue'),
          meta: { middleware: guest }
        },
        {
          path: "/password/reset",
          name: "PasswordReset",
          component: PasswordReset,
          meta: { middleware: guest }
        },
        {
          path: "/password/email",
          name: "PasswordEmail",
          component: PasswordEmail,
          meta: { middleware: guest }
        },
      ]
    },
    {
      path: "/modules",
      component: DashboardLayout,
      name: "Módulos API",
      children: [
        {
          path: "user-profile",
          name: "User Profile",
          components: { default: UserProfile },
          meta: { middleware: auth }
        },
        {
          path: "user-management/list-users",
          name: "Lista Usuarios",
          components: { default: ListUserPage },
          meta: { middleware: auth }
        },
        {
          path: "sections",
          name: "Secciones",
          components: { default: ListSection },
          meta: { middleware: auth }
        },
        {
          path: "create-section",
          name: "Crear Sección",
          components: { default: CreateSection },
          // meta: { middleware: auth }
        },
        {
          path: "edit-section/:id",
          name: "Editar Sección",
          components: { default: EditSection },
          props: true
          // meta: { middleware: auth }
        },
        {
          path: "families",
          name: "Familias",
          components: { default: ListFamily },
          meta: { middleware: auth }
        },
        {
          path: "create-family",
          name: "Crear Familia",
          components: { default: CreateFamily },
          // meta: { middleware: auth }
        },
        {
          path: "edit-family/:id",
          name: "Editar Familia",
          components: { default: EditFamily },
          props: true
          // meta: { middleware: auth }
        },
        {
          path: "users",
          name: "Usuarios",
          components: { default: ListUsers },
          meta: { middleware: auth },
        },
        {
          path: "create-user",
          name: "Crear Usuario",
          components: { default: CreateUser },
          // meta: { middleware: auth }
        },
        {
          path: "edit-user/:id",
          name: "Editar Usuario",
          components: { default: EditUser },
          props: true
          // meta: { middleware: auth }
        },
        {
          path: "attributes",
          name: "Atributos",
          components: { default: ListAttributes },
          meta: { middleware: auth },
        },
        {
          path: "create-attribute",
          name: "Crear Atributo",
          components: { default: CreateAttribute },
          // meta: { middleware: auth }
        },
        {
          path: "edit-attribute/:id",
          name: "Editar Atributo",
          components: { default: EditAttribute },
          props: true
          // meta: { middleware: auth }
        },
        {
          path: "forms",
          name: "Formularios",
          components: { default: ListForms },
          meta: { middleware: auth },
        },
        {
          path: "create-form",
          name: "Crear Formulario",
          components: { default: CreateForm },
          // meta: { middleware: auth }
        },
        {
          path: "preview-form",
          name: "Formulario",
          components: { default: PreviewForm },
          // props: true
          // meta: { middleware: auth }
        },
        {
          path: "preview-form-edit",
          name: "Formulario Preview",
          components: { default: PreviewFormEdit },
          // props: true
          // meta: { middleware: auth }
        },
        {
          path: "edit-form/:id",
          name: "Editar Fromulario",
          components: { default: EditForm },
          props: true
          // meta: { middleware: auth }
        },
        {
          path: "forms-app",
          name: "Formularios App",
          components: { default: ListFormsApps },
          meta: { middleware: auth },
        },
      ]
    },
  ]
});
// Creates a `nextMiddleware()` function which not only
// runs the default `next()` callback but also triggers
// the subsequent Middleware function.
function nextFactory(context, middleware, index) {
  const subsequentMiddleware = middleware[index];
  // If no subsequent Middleware exists,
  // the default `next()` callback is returned.
  if (!subsequentMiddleware)
    return context.next;

  return (...parameters) => {
    // Run the default Vue Router `next()` callback first.
    context.next(...parameters);
    // Then run the subsequent Middleware with a new
    // `nextMiddleware()` callback.
    const nextMiddleware = nextFactory(context, middleware, index + 1);
    subsequentMiddleware({ ...context, next: nextMiddleware });
  };
}

router.beforeEach((to, from, next) => {

  if (to.meta.middleware) {
    const middleware = Array.isArray(to.meta.middleware) ? to.meta.middleware : [to.meta.middleware];
    const context = { from, next, to, router };
    const nextMiddleware = nextFactory(context, middleware, 1);

    return middleware[0]({ ...context, next: nextMiddleware });
  }

  return next();
});

export default router;
