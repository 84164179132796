var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground,"short-title":"Simple Form","title":"Simple Form"}},[_c('template',{slot:"links"},[_c('sidebar-item',{attrs:{"opened":"","link":{
        name: 'Configuración (API)',
        icon: 'ni ni-settings-gear-65',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Módulo Usuarios',
          path: '/modules/users',
        }}}),_c('sidebar-item',{attrs:{"isHidden":"","link":{
          name: 'Crear Usuario',
          path: '/modules/create-user',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Módulo Sección',
          path: '/modules/sections',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Módulo Familias',
          path: '/modules/families',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Módulo Atributos',
          path: '/modules/attributes',
        }}}),_c('sidebar-item',{attrs:{"isHidden":"","link":{
          name: 'Crear Atributo',
          path: '/modules/create-attribute',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: 'Módulo Formularios',
          path: '/modules/forms',
        }}})],1),_c('sidebar-item',{attrs:{"opened":"","link":{
        name: 'Configuración (App)',
        icon: 'ni ni-app',
      }}},[_c('sidebar-item',{attrs:{"link":{
          name: 'Formularios App',
          path: '/modules/forms-app',
        }}})],1),_c('hr',{staticClass:"my-3",staticStyle:{"border":"0","border-top":"1px solid rgba(0, 0, 0, 0.1)","min-width":"80%","overflow":"visible","box-sizing":"content-box","height":"0"}})],1)],2),_c('div',{staticClass:"main-content",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('div',{on:{"click":_vm.toggleSidebar}},[_c('fade-transition',{attrs:{"duration":200,"origin":"center top","mode":"out-in"}},[_c('router-view')],1),(!_vm.$route.meta.hideFooter)?_c('content-footer'):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }