<template>
    <div>
        <base-header type="gradient-success" class="pb-6 pb-6 pt-5 pt-md-5">
        </base-header>
        <div class="container-fluid mt-5">
            <card body-classes="px-0 pb-1" footer-classes="pb-2">
                <div class="card-header border-0">
                    <div class="row align-items-center">
                        <div class="col">
                            <h3 class="mb-0">Listado de secciones</h3>
                        </div>
                        <div class="col text-right">
                            <base-button type="primary" icon size="sm" @click="createSection">
                                <i class="fas fa fa-plus mr-2"></i>
                                <span class="btn-inner--text">Agregar Sección</span>
                            </base-button>
                        </div>
                    </div>
                </div>

                <div class="table-responsive">
                    <base-table class="table align-items-center table-flush" tbody-classes="list" :data="sections"
                        :thead-classes="'thead-light'">
                        <template slot="columns">
                            <th>ID</th>
                            <th>Título</th>
                            <th>Ícono</th>
                            <th>Orden</th>
                            <th>Acciones</th>
                        </template>

                        <template slot-scope="{ row }">
                            <td>
                                {{ row.id }}
                            </td>
                            <td>
                                {{ row.title }}
                            </td>
                            <td>
                                <ion-icon :name="row.icon" size="large"></ion-icon>
                            </td>
                            <td>
                                {{ row.orden }}
                            </td>
                            <td>
                                <a style="cursor: pointer" @click="editSection(row.id)" class="mr-2">
                                    <i class="fas fa-user-edit"></i>
                                </a>
                                <a style="cursor: pointer" @click="openModal(row.id)">
                                    <i class="fas fa-trash"></i>
                                </a>
                            </td>
                        </template>
                    </base-table>
                </div>

                <!--Classic modal-->
                <modal :show.sync="showAlert">
                <h3 slot="header" class="modal-title">Eliminar</h3>
                <p>¿Estás seguro que quieres eliminar el registro?</p>
                <template slot="footer">
                    <base-button type="primary" @click="deleteSection">Aceptar</base-button>
                    <base-button type="link" class="ml-auto" @click="showAlert = false">Cancelar</base-button>
                </template>

                </modal>

                <div class="card-footer d-flex justify-content-end">
                    <base-pagination total="1"></base-pagination>
                </div>
            </card>
        </div>
    </div>
</template>
<script>
import router from "@/router";
import lisServices from '../../store/services/getList-service';

export default {
    data() {
        return {
            sections: [],
            showAlert: false,
            sectionId: null
        };
    },
    methods: {
        openModal(id) {
            this.showAlert=true;
            this.sectionId = id;
        },
        createSection() {
            router.push({ path: '/modules/create-section' });
        },
        async getSections() {
            let response = await lisServices.getListSections();
            console.log('Sections', response);
            this.sections = response.list;
            console.log(this.sections);
        },
        editSection(id) {
            console.log(id)
            router.push({ path: '/modules/edit-section/'+id });
            // this.$router.push('/dashboard')
            
        },
        async deleteSection(){
            let response = await lisServices.deleteSection(this.sectionId);
            console.log('Attributes', response);
            this.showAlert = false;
            if(response.status){
                this.getSections();
                this.$notify({
                    type: "success",
                    message: "¡Operación ejecutada correctamente!",
                });
            }else{
                this.$notify({
                    type: "danger",
                    message: "Error en la operación",
                });
            }
           
        }
    },
    mounted() {
        this.getSections()
    },
};
</script>
<style></style>
  