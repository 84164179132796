import service from '@/store/services/profile-service';

const state = {
  user: null,
  companies: [],
  form : null,
  role : null,
  company : null
};

const mutations = {
  SET_RESOURCE: (state, payload) => {
    state.user = payload;
  },
  SET_FORM: (state, payload) => {
    state.form = payload;
  },
  SET_COMPANY: (state, payload) => {
    state.form = payload;
  },
};

const actions = {
  profile({commit, dispatch}, params) {
    console.log('Params',params);
    return service.get(params)
      .then((profile) => {
        commit('SET_RESOURCE', profile.list);
      });
  },

  update({commit, dispatch}, params) {
    return service.update(params)
      .then((profile) => {
        commit('SET_RESOURCE', profile);
      });
  },

  previewForm(context, payload) {
    console.log('Payload',payload);
    return context.commit('SET_FORM', payload);
  },

  selectedCompany(context, payload) {
    console.log('Payload',payload);
    return context.commit('SET_COMPANY', payload);
  },

};

const getters = {
  profile: state => state.user,
  getForm: state => state.form,
  getSelectedCompany: state => state.company,
};

const profile = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};

export default profile;
